import React from 'react'
import { BackgroundImage } from 'components/BackgroundImage'
import { card, overlay, wrapper, cardHeading } from './Card.module.scss'

export const Card = ({ backgroundColor, backgroundImg, heading, children }) => {
  return (
    <BackgroundImage
      Tag='article'
      className={card}
      backgroundColor={backgroundColor}
      image={backgroundImg}>
      <div className={overlay} />
      <div className={wrapper}>
        <h4 className={cardHeading}>{heading}</h4>
        {children}
      </div>
    </BackgroundImage>
  )
}
