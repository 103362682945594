import React from 'react'
import { Link } from 'gatsby'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { getImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'

import { Card } from 'components/Card'

export const StrainCard = ({
  name,
  photo,
  isSoldOut,
  availability,
  slug,
  currentPage,
}) => {
  const backgroundImage = getImage(photo)

  // last strain card links to calendar component and not a page like the others
  const Container = ({ children }) => {
    return slug !== '/zz-coming-soon/' ? (
      <Link
        to={`/strains${slug}`}
        state={{ fromStrainPage: currentPage }}
        className='iliad__strain-collection--card-container'>
        {children}
      </Link>
    ) : (
      <button
        className='iliad__strain-collection--card-container button'
        onClick={() => scrollTo('#page-section-1')}>
        {children}
      </button>
    )
  }

  return (
    <Container>
      <Card
        backgroundColor='#000'
        backgroundImg={backgroundImage}
        heading={name}>
        <span className='iliad__strain-card--harvest-time'>{availability}</span>
      </Card>
      {isSoldOut && (
        <span className='iliad__strain-card--banner-text'>Sold Out</span>
      )}

      {slug === '/zz-coming-soon/' && (
        <div className='iliad__strain-card--icon'>
          <FaRegCalendarAlt />
          <span>Release Calendar</span>
        </div>
      )}
    </Container>
  )
}

export default StrainCard
